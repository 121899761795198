import {
    SpotlightWaitlistConstants
} from '../View/exportComponent';
import { customJsonstringify } from '../../../adapters/helpers/Utils';

const mixtureParticipationAPI = process.env.MIXTURE_CHAMPAIGN_PARTICIPATION;
const mixtureChannelId = process.env.MIXTURE_CHANNEL_ID;
const mixtureCampaignId = process.env.MIXTURE_CHAMPAIGN_ID;
const mixtureLocale = process.env.MIXTURE_CAMPAIGN_LOCALE;
const mixtureTraitIdValue = process.env.MIXTURE_TRAIT_ID_VALUE;
const mixtureTraitSource = process.env.MIXTURE_TRAIT_SOURCE;
const mixtureOptinIdEmail = process.env.MIXTURE_OPTIN_ID_EMAIL;
const mixtureUpdateTraitAPI = process.env.MIXTURE_UPDATE_TRAIT_API;

function handleTraitsId(traitsData, mixtureTraitValue, mixtureTraitName) {
    let traitValue = mixtureTraitValue;
    let traitsObj = traitsData.filter((item) => item.trait.id === String(mixtureTraitValue));
    if (traitsObj.length > 0) {
        traitValue = traitsObj?.[0]?.trait?.id;
    } else {
        traitsObj = traitsData.filter((item) => item.trait.name === mixtureTraitName);
        if (traitsObj.length > 0) {
            traitValue = traitsObj?.[0]?.trait?.id;
        }
    }
    return traitValue;
}

function handleOptinId(optinsData, mixtureOptinId) {
    let optinId = mixtureOptinId;
    let optinObj = optinsData.filter((item) => item.optIn.id === String(mixtureOptinId));
    if (optinObj.length > 0) {
        optinId = optinObj?.[0]?.optIn?.id;
    }
    return optinId;
}

function removeTags(htmlContent) {
    return htmlContent?.replace(/(<([^>]+)>)/ig, '') || '';
}

function mixtureFormUpdateTraitsPayload(traitsValue) {
    const body = {};
    body['traits'] = [
        {
            "traitId": mixtureTraitIdValue,
            "value": (traitsValue.length > 0) ? traitsValue : ['']
        }
    ];
    return body;
}

function mixtureFormRegisterPayload(email, countryCode, traitsData, optinsData, emailDisclaimerTop, emailOptInStatus, traitsSourceValue) {
    let body = {};
    body.campaign = {
        'campaignId': Number(mixtureCampaignId),
        'locale': mixtureLocale,
        "countryCode": countryCode,
    };

    body.consumer = {
        'email': email
    };
    body.traits = [
        {
            'traitId': handleTraitsId(traitsData, mixtureTraitSource, SpotlightWaitlistConstants.transmitterSource),
            'value': [traitsSourceValue]
        }
    ];
    if (emailOptInStatus) {
        body.optIns = [
            {
                'optinId': handleOptinId(optinsData, mixtureOptinIdEmail),
                'optinText': removeTags(emailDisclaimerTop)
            }
        ];
    }
    return body;
}

export const mixtureFormRegister = async ({ email, countryCode, traitsData, optinsData, emailDisclaimerTop, emailOptInStatus, traitsSourceValue }) => {
    try {
        const body = mixtureFormRegisterPayload(email, countryCode, traitsData, optinsData, emailDisclaimerTop, emailOptInStatus, traitsSourceValue);
        const response = await fetch(mixtureParticipationAPI, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "channelId": mixtureChannelId
            },
            body: customJsonstringify(body)
        });
        if (!response.ok) {
            return response;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export const updateTraitsMixtureFormRegister = async ({ traitsValue, participationID }) => {
    try {
        const body = mixtureFormUpdateTraitsPayload(traitsValue);
        const url = mixtureUpdateTraitAPI + '/' + participationID + '/traits'
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "channelId": mixtureChannelId
            },
            body: customJsonstringify(body)
        });
        if (!response.ok) {
            return response;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}